import React from "react"
import { graphql } from "gatsby"
import { Banner } from "../HeroBanner-types"
import "components/hero/hero-common.module.scss"
import "./hero.module.scss"

import { HeroContent, HeroContentBaseClassName } from "../HeroContent"
import { useHeroComponents } from "../hooks/useHeroComponents"
import { GenerateVariationPrefixes } from "../_types/hero-classes-types"
import { BannerWrapper } from "../BannerWrapper/BannerWrapper"
import { GoogleReview } from "components/google-reviews-banner/GoogleReview"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import { Divider } from "../Divider"
import { HeroHighlights } from "components/highlights/Hero/HeroHighlights"
import { SecondaryImage } from "../SecondaryImage/SecondaryImage"

interface HeroProps {
  banner: Banner
}

export const HeroBanner = ({ banner }: HeroProps) => {
  const {
    background_image,
    buttons,
    components,
    heading,
    secondary_image,
    sub_heading,
  } = banner

  const prefix: GenerateVariationPrefixes = "fr-hero",
    backgroundImageUrl = background_image?.value[0]?.url

  const { highlights } = useHeroComponents(components?.modular_content)
  const { googleReviewsUrl } = useIntegrationsSettings()

  const bannerContentBaseClassName =
    `${prefix}__banner-content` as HeroContentBaseClassName

  const hasSecondaryImage =
    secondary_image && secondary_image?.value?.length > 0

  const contentClassName = hasSecondaryImage
    ? `${bannerContentBaseClassName}-with-image`
    : `${bannerContentBaseClassName}`

  return (
    <>
      <BannerWrapper
        backgroundImageUrl={backgroundImageUrl}
        contentClassName={contentClassName}
        prefix={prefix}
      >
        <div className={`fr-hero__body`}>
          <div className="fr-hero__body--left">
            <GoogleReview url={googleReviewsUrl} />
            <HeroContent
              baseClassName={contentClassName as HeroContentBaseClassName}
              buttons={buttons?.value}
              heading={heading?.value}
              subHeading={sub_heading}
            />
          </div>
          <div className="fr-hero__body--right">
            {hasSecondaryImage && (
              <SecondaryImage
                image={secondary_image?.value[0]}
                prefix="fr-hero"
              />
            )}

            {!hasSecondaryImage && highlights && highlights.length > 0 && (
              <div className={`fr-hero__highlights`}>
                <HeroHighlights highlights={highlights} />
              </div>
            )}
          </div>
        </div>
      </BannerWrapper>
      <Divider />
    </>
  )
}

export const query = graphql`
  fragment HeroBannerFragment on kontent_item_hero_banner {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      tagline {
        value
        type
      }
      secondary_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      components {
        modular_content {
          ...FormFragment
          ...HighlightGroupFragment
        }
      }
      buttons {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
