import React from "react"
import { graphql, PageProps } from "gatsby"
import { SEO } from "components/seo/seo"
import { HeroBanner } from "components/hero/HeroBanner"
import { GlobalFooter } from "components/global-footer/GlobalFooter"
import { useLogos } from "hooks/queries/settings/useLogos"
import { ContentBlocks } from "components/content-blocks/ContentBlocks"
import { Layout } from "components/layout"
import { StubBanner } from "components/form-stub/StubBanner"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { TabbedHeroBanner } from "components/hero/TabbedHeroBanner"
import { LocalBusinessSchema } from "components/schema/LocalBusinessSchema"

type PageContext = {
  breadcrumb: {
    crumbs: Record<string, string>[]
    location: string
  }
  slug: string
  linkedPagesUrlPathCodex: Record<string, string>
}

interface AlternateHomepageTemplateProps extends PageProps {
  pageContext: PageContext
  data: any
}

const AlternateHomepageTemplate: React.FC<AlternateHomepageTemplateProps> = ({
  location,
  pageContext,
  data,
}) => {
  const { pathname, hash } = location
  const { slug, linkedPagesUrlPathCodex } = pageContext

  const { globalFormStubBanner } = useGeneralSettings()

  const { headerLogo, faviconLogo } = useLogos()

  const [page] = data.allKontentItemPage.nodes

  const {
    custom_tracking_number,
    seo_metadata__meta_title,
    seo_metadata__meta_description,
    seo_metadata__canonical_link,
    seo_metadata__rich_data_image,
    seo_metadata__rich_data_link,
    alternating_content,
    banner,
    rich_content,
    global_footer,
    is_location_page,
  } = page.elements

  const hasAlternatingContentBlocks =
    alternating_content.value[0]?.codename === "no" ? false : true

  const isLocationPage =
    is_location_page?.value?.length > 0 &&
    is_location_page?.value[0]?.codename === "yes"
      ? true
      : false

  return (
    <Layout
      location={slug}
      pathName={pathname}
      hash={hash}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
      isHomepage
      customTrackingNumber={custom_tracking_number?.value as string | undefined}
    >
      <SEO
        title={seo_metadata__meta_title?.value}
        description={seo_metadata__meta_description?.value}
        canonical={seo_metadata__canonical_link?.value}
        image={seo_metadata__rich_data_image}
        fallbackImage={headerLogo}
        favicon={faviconLogo}
      />

      {isLocationPage && (
        <LocalBusinessSchema
          serviceArea={seo_metadata__meta_title.value}
          richDataImageUrl={seo_metadata__rich_data_image?.value[0]?.url! || ""}
          richDataLink={seo_metadata__rich_data_link?.value! || ""}
        />
      )}

      {banner.value[0].elements.hasOwnProperty("hero_tabs") ? (
        <TabbedHeroBanner banner={banner.value[0].elements} />
      ) : (
        <HeroBanner banner={banner.value[0].elements} />
      )}

      {globalFormStubBanner && Object.keys(globalFormStubBanner).length > 0 && (
        <StubBanner formStub={globalFormStubBanner} />
      )}
      {rich_content?.modular_content?.length > 0 && (
        <ContentBlocks
          content={rich_content.modular_content}
          hasAlternatingContentBlocks={hasAlternatingContentBlocks}
        />
      )}

      {global_footer && global_footer?.value.length > 0 && (
        <GlobalFooter content={global_footer} />
      )}
    </Layout>
  )
}

export const getAlternateHomepageTemplate = graphql`
  query ($slug: String!) {
    allKontentItemPage(
      filter: { elements: { slug: { value: { eq: $slug } } } }
    ) {
      nodes {
        elements {
          custom_tracking_number {
            value
          }
          alternating_content {
            value {
              codename
            }
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          seo_metadata__rich_data_image {
            value {
              url
            }
          }
          seo_metadata__rich_data_link {
            value
          }
          is_location_page {
            value {
              codename
            }
          }
          noindex_this_page {
            value {
              codename
            }
          }
          banner {
            value {
              ...HeroBannerFragment
              ...TabbedHeroBannerFragment
            }
          }
          rich_content {
            ...ContentBlocksParserFragment
          }
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
        }
      }
    }
  }
`

export default AlternateHomepageTemplate
